import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import { relatedOffice } from "~/queries/officeQuery"; // eslint-disable-line
import { fullOfficeContacts } from "~/queries/officeContactsQuery"; // eslint-disable-line
import Layout from "../components/layout";
import PageWrapper from "../components/Structure/PageWrapper";
import Heading from "../components/Heading";
import SocialNetworks from "../components/SocialNetworks";
import PrimaryContacts from "../components/PrimaryContacts";
import MapCircle from "../components/Map/MapCircle";
import CardSelections from "../components/CardSelections";
import SEO from "../components/seo";

class OfficePost extends Component {
	render() {
		const {
			data: {
				recraft: { post },
				recraft: { related }
			}
		} = this.props;

		const SocialNetworkList = () => {
			return <SocialNetworks items={post.socializr} />;
		};

		const PrimaryContactList = () => {
			return <PrimaryContacts item={post.primaryContact[0]} />;
		};

		return (
			<Layout location={this.props.location}>
				<SEO
					title={post.title}
					keywords={[`dumbo`, `places`, `dumbo businesses`]}
				/>
				<PageWrapper
					mainContent={
						<div>
							<Link
								to="/offices"
								className="uppercase text-xs tracking-wide text-grey-dark mb-4 block"
							>
								/ &ensp; Offices
							</Link>
							<Heading>{post.title}</Heading>
						</div>
					}
					body={post.body ? post.body.content : null}
					sidebarContent={
						<div>
							<h2>Sidebar Content</h2>
							<MapCircle post={post} />
							<PrimaryContactList />
							<SocialNetworkList />
						</div>
					}
				/>
				{related.length ? <CardSelections post={post} items={related} /> : null}
			</Layout>
		);
	}
}

export default OfficePost;

export const pageQuery = graphql`
	query OfficePostByUri($uri: String!, $id: [Int]) {
		recraft {
			post: entry(uri: $uri) {
				... on Recraft_Offices {
					addressSelector {
						... on Recraft_Addresses {
							title
							uberAddress {
								zip
								lat
								lng
							}
						}
					}
				}
				...relatedOffice
				...fullOfficeContacts
			}
			related: entries(relatedTo: [{ element: $id }], section: news) {
				... on Recraft_News {
					title
					id
					uri
					featuredImage {
						url
						focalPoint {
							x
							y
						}
					}
					newsCategory {
						title
					}
				}
			}
		}
	}
`;
